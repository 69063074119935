import React from "react";

import "./HeroContainer.css";
import bgVideo from "../../../assets/HomeImg/course-video.mp4";
import HeroCarousel from "./HeroCarousel";
import HeroVideoCarousel from "./HeroVideoCarousel";

function HeroContainer() {
  return (
    <div className=" h-full">
      {/* <div className=" flex justify-center bg-gray-900 ">
        <video
          src={bgVideo}
          autoPlay
          muted
          loop
          className="object-cover h-[90vh] w-full bg-fixed"
        ></video>

        <div className="flex justify-center w-full bg-[rgba(17,24,39,.8)] h-[90vh] absolute">
          <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
            <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
              <p className="font-bold text-2xl xl:text-5xl tablet:text-3xl leading-normal">
                Delivering impactful product experiences that shape the future,
              </p>
              <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                we specialize in building tomorrow's solutions today.
              </p>

              <p className="">
                <button className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full text-xl font-bold hover:text-orange-600 hover:bg-transparent">
                  Learn More
                </button>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <HeroCarousel /> */}
      <HeroVideoCarousel />
    </div>
  );
}

export default HeroContainer;
