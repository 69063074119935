import React from 'react'

function Exc() {
  return (
    <div>

        

    </div>
  )
}

export default Exc