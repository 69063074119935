import React from "react";

import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useMediaQuery } from "react-responsive";

function ProfileCardCarousel() {
  const isMobile = useMediaQuery({ query: "(max-width: 415px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const visibleSlides = isMobile ? 1 : isTablet ? 2 : 3;
  const profileData = [
    {
      name: "Ankit Soni",
      branch: "Mobile Developer",
      description:
        "Mobile Developer | Passionate about crafting engaging mobile experiences | Code enthusiast ",
    },
    {
      name: "Shashank Shekhar",
      branch: "Senior Java Developer",
      description:
        "12+ Year of Experience, In the realm of Java development, expertise and experience are paramount. Meet Shashank Shekhar, a seasoned Senior Java Developer with a proven track record of delivering robust solutions and driving innovation in software development.",
    },
    {
      name: "Vivek Kumar",
      branch: "Marketting Lead",
      description:
        "In the ever-evolving landscape of book marketing, having a visionary leader can make all the difference. Introducing Vivek Kumar, an innovative marketing expert ready to redefine the way your books captivate audiences and dominate the market.",
    },
    {
      name: "Parvez Alam",
      branch: "Operations Head",
      description:
        "20+ Year Experience, In the dynamic landscape of operations management, having a visionary leader can be the key to unlocking efficiency, productivity, and success. Allow us to introduce Parvez Alam, an experienced operations expert poised to optimize your business processes and drive organizational excellence.",
    },
    {
      name: "Kushal Kundu",
      branch: "Android Developer",
      description:
        " In the ever-expanding world of mobile technology, having a skilled Android developer can be the difference between a mediocre app and a standout success. Meet Kushal Kundu, a talented Android developer ready to bring your app ideas to life with creativity, precision, and innovation.",
    },
    {
      name: "Manoj",
      branch: "QA Lead ",
      description:
        "In the realm of software development, quality assurance is paramount. Introducing Manoj, a seasoned QA Lead with a passion for ensuring flawless user experiences and driving product excellence through rigorous testing and meticulous attention to detail.",
    },
    {
      name: "Vasudev Raghav ",
      branch: "Backend Developer",
      description:
        "Vasudev Raghav is a seasoned backend developer with a passion for crafting robust and efficient software solutions. With a keen eye for detail and a knack for problem-solving, Vasudev excels in architecting scalable backend systems that power today's digital world.",
    },
    {
      name: "Krishna Kumar Gautam",
      branch: "Frontend Engineer",
      description:
        "Krishna Kumar Gautam is a dedicated frontend engineer who is passionate about crafting immersive user experiences and pushing the boundaries of web development. With a keen eye for design and a deep understanding of modern web technologies, Krishna excels in building intuitive and responsive interfaces that captivate and engage users.",
    },
    // {
    //   name: "Manvendra Singh",
    //   branch: " Full Stack Developer ",
    //   description:
    //     "Manvendra Singh is a versatile Full Stack Developer with a passion for creating seamless and robust web applications from concept to deployment. With a holistic understanding of both frontend and backend technologies, Manvendra thrives in bridging the gap between user experience and server-side functionality.",
    // },
    // {
    //   name: "Ashutosh ",
    //   branch: " Front end developer ",
    //   description:
    //     "Ashutosh is a talented Frontend Developer with a flair for creating captivating user interfaces and seamless user experiences. With a keen eye for design and a passion for cutting-edge web technologies, Ashutosh excels in turning ideas into visually stunning and highly functional web applications.",
    // },
  ];

  return (
    <div className="parent w-full  relative">
      <CarouselProvider
        visibleSlides={visibleSlides}
        totalSlides={profileData?.length}
        step={1}
        naturalSlideWidth={100}
        naturalSlideHeight={500}
        isIntrinsicHeight
        infinite={true}
        isPlaying={true}
      >
        <Slider>
          {profileData.map((data, index) => (
            <Slide index={index} key={index}>
              <div className="flex   h-full">
                <div
                  className={` flex items-center justify-center flex-col mx-5   h-full`}
                >
                  <div className=" w-[25%] relative top-16 tablet:top-20 ">
                    <img
                      src={require("../../../assets/awtar.jpg")}
                      alt=""
                      className="rounded-[50%] bg-white  "
                    />
                  </div>

                  <div className="text-center text-gray-400 pt-20  w-full border-2 rounded-lg px-5 flex justify-center flex-col items-center h-full ">
                    <p className="font-bold text-2xl my-5 text-white">
                      {data.name}
                    </p>
                    <p className="pb-5 text-sm text-gray-500">{data.branch}</p>
                    <div className="h-[2px] bg-orange-500 w-[20%]"></div>
                    <p className="text-xs mt-5 mb-10 px-5">
                      {data.description}{" "}
                    </p>
                    <div className="flex justify-end  w-full">
                      <p className=" pe-1 text-orange-500 ">
                        <Link to="">Read More</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
        <div className=" w-full top-0  right-5 h-[2rem] flex justify-end absolute items-end z-10 gap-3">
          <ButtonBack className=" border lg:text-xl p-2 rounded-lg hover:bg-gray-50 hover:text-gray-700">
            <IoIosArrowBack />
          </ButtonBack>
          <ButtonNext className=" border lg:text-xl p-2 rounded-lg hover:bg-gray-50 hover:text-gray-700">
            <IoIosArrowForward />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
}

export default ProfileCardCarousel;
