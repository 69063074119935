import React from "react";
import ReactDom from "react-dom";

import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

const root = document.getElementById("root");

ReactDom.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root
);
