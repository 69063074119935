import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import video1 from "../../../assets/HomeImg/course-video.mp4";
import video2 from "../../../assets/heroVideo1.mp4";
import video3 from "../../../assets/heroVideo2.mp4";
import video4 from "../../../assets/herovideo4.mp4";

function HeroVideoCarousel() {
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      infiniteLoop
      autoPlay
    >
      <div>
        <video
          src={video1}
          autoPlay
          muted
          loop
          className="object-cover h-[90vh] w-full bg-fixed"
        ></video>
        <div className="flex justify-center w-full bg-[rgba(17,24,39,.7)] top-0 bottom-0 absolute">
          <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
            <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
              <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                Delivering impactful product experiences that shape the future,
              </p>
              <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                we specialize in building tomorrow's solutions today.
              </p>

              <p className="">
                <button
                  className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full  font-bold hover:text-orange-600 hover:bg-transparent"
                  onClick={() => {
                    console.log("csdfgghfd");
                  }}
                >
                  Learn More
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <video
          src={video2}
          autoPlay
          muted
          loop
          className="object-cover h-[90vh] w-full bg-fixed"
        ></video>
        <div className="flex justify-center w-full bg-[rgba(17,24,39,.7)] top-0 bottom-0 absolute">
          <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
            <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
              <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                Delivering impactful product experiences that shape the future,
              </p>
              <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                we specialize in building tomorrow's solutions today.
              </p>

              <p className="">
                <button
                  className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full  font-bold hover:text-orange-600 hover:bg-transparent"
                  onClick={() => {
                    console.log("csdfgghfd");
                  }}
                >
                  Learn More
                </button>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <video
          src={video3}
          autoPlay
          muted
          loop
          className="object-cover h-[90vh] w-full bg-fixed"
        ></video>
        <div className="flex justify-center w-full bg-[rgba(17,24,39,.7)] top-0 bottom-0 absolute">
          <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
            <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
              <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                Enriching Learning
              </p>
              <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                Welcome to the future of education! Our innovative classroom
                software solutions are designed to revolutionize teaching and
                learning experiences. From interactive lessons to seamless
                administration, we're here to enhance every aspect of the
                educational journey.
              </p>

              <p className="">
                <button
                  className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full  font-bold hover:text-orange-600 hover:bg-transparent"
                  onClick={() => {
                    console.log("csdfgghfd");
                  }}
                >
                  Learn More
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <video
          src={video4}
          autoPlay
          muted
          loop
          className="object-cover h-[90vh] w-full bg-fixed"
        ></video>
        <div className="flex justify-center w-full bg-[rgba(17,24,39,.7)] top-0 bottom-0 absolute">
          <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
            <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
              <p className="font-bold  xl:text-3xl tablet:text-3xl leading-normal">
                Join us on the forefront of AI innovation and embark on a
                journey towards success
              </p>
              <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                Embrace the future of technology with our cutting-edge AI
                solutions. From intelligent automation to data-driven insights,
                we're here to revolutionize your business. Our team of experts
                specializes in leveraging artificial intelligence to drive
                innovation, efficiency, and growth.
              </p>

              <p className="">
                <button
                  className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full  font-bold hover:text-orange-600 hover:bg-transparent"
                  onClick={() => {
                    console.log("csdfgghfd");
                  }}
                >
                  Learn More
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}

export default HeroVideoCarousel;
