import React from 'react'

import { Link } from 'react-router-dom'

import { AiFillApple } from 'react-icons/ai'
import {ImMobile} from "react-icons/im"
import {BsAndroid2, BsGearFill} from "react-icons/bs"
import {TbBrandDolbyDigital, TbWorldWww} from "react-icons/tb"
import {SiMaterialdesignicons} from "react-icons/si"

function ServicesDropdown() {

    const serviceCardData = [
        {
            icon: <TbBrandDolbyDigital className='' />,
            heading: 'Digital Transformation'
        },
        {
            icon: <BsGearFill className='' />,
            heading: 'Software App Development'
        },
        {
            icon: <TbWorldWww className='' />,
            heading: 'Web Design and Development'
        },
        {
            icon: <ImMobile className='' />,
            heading: 'Mobile App Development'
        },
        {
            icon: <BsAndroid2 className='' />,
            heading: 'Android App Development'
        },
        {
            icon: <AiFillApple className='' />,
            heading: 'iOS App Development'
        },
        {
            icon: <SiMaterialdesignicons className='' />,
            heading: 'UX/UI Design'
        },
    ]

 
    return (
        <div className=''>

            <div className=" bg-white border-t-4 border-orange-500  pb-10  flex justify-between flex-col 2xl:flex-row px-5 2xl:absolute left-0 right-0 2xl:mx-auto  self-center z-50 rounded-lg w-[90%] ">

                <div className="w-full 2xl:w-[30%] mt-10">

                    <div className="2xl:mx-5">

                        <ul className="">
                            <li className="  my-2 py-3">
                                <Link to='' className='  w-full 2xl:text-xl border-orange-600 font-bold border-l-4 py-3 px-5  text-gray-600' >Application Development</Link>
                            </li>
                            <li className="  my-2 py-3">
                                <Link to='' className='  w-full 2xl:text-xl border-orange-600  hover:border-l-4 py-3 px-5 text-gray-600' >Resource Augmentation</Link>
                            </li>
                            <li className="  my-2 py-3">
                                <Link to='' className='  w-full 2xl:text-xl border-orange-600  hover:border-l-4 py-3 px-5 text-gray-600' >Cloud Services</Link>
                            </li>
                            <li className="  my-2 py-3">
                                <Link to='' className='  w-full 2xl:text-xl border-orange-600  hover:border-l-4 py-3 px-5 text-gray-600' >Data Science</Link>
                            </li>
                        </ul>

                    </div>

                </div>

                <div className="w-full 2xl:w-[70%]  flex items-center justify-center flex-wrap 2xl:justify-start text-gray-600">

                    {
                        serviceCardData.map((data) =>

                            <div className=" m-3 p-3 flex flex-col items-center justify-center rounded-xl border-2 border-transparent hover:border-orange-200 cursor-pointer">
                                <div className="bg-orange-600 p-3 my-3 text-4xl rounded-full text-white">
                                    {data.icon}
                                </div>
                                <p className=' text-lg font-semibold '>{data.heading}</p>
                            </div>

                        )
                    }

                    

                </div>

            </div>

        </div>
    )
}

export default ServicesDropdown