import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import lit from "../../assets/lit.png";
import dexlabz from "../../assets/dexlabz.png";
import bjmy from "../../assets/bjym.png";
import reproneet from "../../assets/reproneet.png";
import easydev from "../../assets/easydev.png";

function Protfolio() {
  const [showBox, setShowBox] = useState(null);

  const card = [
    {
      image: dexlabz,
      link: "https://mvp-dev.dexlabz.com",
      title: "DEXLABZ",
    },
    {
      image: reproneet,
      link: "https://www.reproneetlearning.com",
      title: "REPRONEET",
    },
    {
      image: easydev,
      link: "https://www.easydev.in",
      title: "EASYDEV",
    },
  ];

  const handleMouseEnter = (index) => {
    if (index === index) {
      setShowBox(index);
    }
  };
  const handleMouseLeave = (index) => {
    if (index === index) {
      setShowBox(null);
    }
  };

  return (
    <div className="">
      <div>
        <Header />
      </div>

      <div className="bg-gray-900 flex justify-center flex-col items-center contact-bg-img">
        <div className="bg-[rgba(15,23,42,.7)] flex justify-center flex-col items-center w-full">
          <div className=" text-white flex flex-col xl:flex-row  xl:w-[70%] 6xl:w-[60%] mx-5 items-start  justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 mt-10 font-bold text-5xl py-10">
            <p className="">Portfolio</p>
          </div>

          <div className=" border rounded-2xl my-10 flex flex-wrap w-[70%] justify-center">
            {card?.map((card, index) => (
              <div
                key={index}
                className="w-[300px] m-10 cursor-pointer relative "
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <img src={card?.image} alt="" />
                {showBox === index && (
                  <div
                    className={`bg-[rgba(15,23,42,.7)] w-full absolute top-0 text-white flex flex-col h-full justify-center items-center `}
                  >
                    <p className="text-center font-bold text-lg">
                      {card?.title}
                    </p>
                    <p className="text-center">{card?.link}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Protfolio;
