import React from 'react'

import './CareerHeroContainer.css'

function CareerHeroContainer() {
   return (
      <div>

         <div className=" flex justify-center flex-col items-center career-bg-img w-full">



            <div className="   flex justify-center flex-col items-center ">

               <div className=" text-white flex flex-col xl:flex-row  xl:w-[80%] mx-5 items-center  justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 py-10  bg-[rgba(0,0,0,.4)] px-5 md:px-20 my-20 rounded-3xl">

                  <div className="xl:w-[50%] tablet:w-[50%] my-5 me-10">

                     <p className="font-bold text-2xl xl:text-4xl mb-5">Career</p>
                     <p className="text-xs xl:text-base">Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque maxime eius dicta culpa fugit accusamus architecto sit, reiciendis perferendis,  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente iure omnis dignissimos, tenetur natus repudiandae quidem nulla dolorem, blanditiis, sequi ipsa iusto. Repellat dolore, quidem qui soluta earum ea maiores?   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae soluta cum cupiditate iste tempore odit laborum. Eveniet non, autem repellendus numquam modi, voluptatem similique cum hic reiciendis et, maxime dignissimos.</p>

                  </div>

                  <div className="w-full xl:w-[50%] tablet:w-[50%] mt-10 md:mt-0">

                     <img src={require('../../../assets/HomeImg/career1.png')} alt="" />


                  </div>



               </div>

            </div>

         </div>

      </div>
   )
}

export default CareerHeroContainer
