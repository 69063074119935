import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  ImageWithZoom,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import video2 from "../../../assets/heroVideo1.mp4";
import video3 from "../../../assets/heroVideo2.mp4";
import video1 from "../../../assets/HomeImg/course-video.mp4";

function HeroCarousel() {
  return (
    <div className="h-full relative ">
      <CarouselProvider
        visibleSlides={1}
        totalSlides={3}
        step={1}
        naturalSlideWidth={400}
        naturalSlideHeight={200}
        infinite={true}
        isPlaying={true}
        // hasMasterSpinner
        lockOnWindowScroll
      >
        <div className=" w-full top-0 bottom-0 my-auto h-[2rem] flex justify-between absolute items-end z-10">
          <ButtonBack className="bg-white    lg:text-3xl p-2 lg:p-5 rounded-full">
            <IoIosArrowBack />
          </ButtonBack>
          <ButtonNext className="bg-white    lg:text-3xl p-2 lg:p-5 rounded-full">
            <IoIosArrowForward />
          </ButtonNext>
        </div>
        <Slider >
          <Slide index={0} className=" h-[80vh] relative">
            <video
              src={video1}
              autoPlay
              muted
              loop
              className="object-cover h-[90vh] w-full bg-fixed"
            ></video>
            <div className="flex justify-center w-full bg-[rgba(17,24,39,.8)] top-0 bottom-0 absolute">
              <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
                <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
                  <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                    Delivering impactful product experiences that shape the
                    future,
                  </p>
                  <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                    we specialize in building tomorrow's solutions today.
                  </p>

                  <p className="">
                    <button
                      className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full lg:text-xl font-bold hover:text-orange-600 hover:bg-transparent"
                      onClick={() => {
                        console.log("csdfgghfd");
                      }}
                    >
                      Learn More
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={0} className=" h-[80vh] relative">
            <video
              src={video2}
              autoPlay
              muted
              loop
              className="object-cover h-[90vh] w-full bg-fixed"
            ></video>
            <div className="flex justify-center w-full bg-[rgba(17,24,39,.8)] top-0 bottom-0 absolute">
              <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
                <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
                  <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                    Delivering impactful product experiences that shape the
                    future,
                  </p>
                  <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                    we specialize in building tomorrow's solutions today.
                  </p>

                  <p className="">
                    <button className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full lg:text-xl font-bold hover:text-orange-600 hover:bg-transparent">
                      Learn More
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={0} className=" h-[80vh] relative">
            <video
              src={video3}
              autoPlay
              muted
              loop
              className="object-cover h-[90vh] w-full bg-fixed"
            ></video>
            <div className="flex justify-center w-full bg-[rgba(17,24,39,.8)] top-0 bottom-0 absolute">
              <div className=" text-white flex flex-col xl:flex-row xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-row ">
                <div className="xl:w-[50%] px-5  my-5 py-5 text-center">
                  <p className="font-bold  xl:text-5xl tablet:text-3xl leading-normal">
                    Delivering impactful product experiences that shape the
                    future,
                  </p>
                  <p className="text-sm xl:text-xl xl:my-3 text-gray-400 tablet:text-base tablet:mt-2">
                    we specialize in building tomorrow's solutions today.
                  </p>

                  <p className="">
                    <button className="bg-orange-600  text-white border-2 border-orange-600 py-1 xl:py-3 px-5 xl:px-16 my-10 rounded-full lg:text-xl font-bold hover:text-orange-600 hover:bg-transparent">
                      Learn More
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <Dot />
          </Slide>
        </Slider>
        <div className="w-full flex justify-center gap-5 ">
          <DotGroup
          currentSlide={1}
            showAsSelectedForCurrentSlideOnly={true}
            className="mx-10 size-5 "
          />
        </div>
      </CarouselProvider>
    </div>
  );
}

export default HeroCarousel;
