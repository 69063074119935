import React from "react";

import "./OurServices.css";

import { BsFillPatchPlusFill } from "react-icons/bs";
import { TbBulbFilled } from "react-icons/tb";
import {
  FaHeadSideVirus,
  FaMobileRetro,
  FaRobot,
  FaTowerObservation,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

function OurServices() {
  const OurServicescardData1 = [
    {
      divStyle: "bg-[#2a303e]  xl:rounded-tl-xl",
      heading: "Mobile App Development",
      discretion:
        "At PhynLabz, we specialize in crafting mobile applications that are not just functional but also user-friendly and visually appealing. Whether it's iOS, Android, or cross-platform development, we ensure your app stands out in the crowded app market.",
    },
    {
      divStyle: "bg-gray-800",
      heading: "Web Development",
      discretion:
        "Our web development team is skilled in creating responsive, feature-rich websites that leave a lasting impression. We leverage the latest web technologies to build robust and scalable web solutions that align with your business goals.",
    },
    {
      divStyle: "bg-[#2a303e]",
      heading: "Cross-Platform Development",
      discretion:
        "PhynLabz excels in cross-platform development, allowing you to reach a wider audience with a single codebase. Our expertise in frameworks like React Native and Flutter ensures your app performs seamlessly on multiple platforms while reducing development costs.",
    },
    {
      divStyle: "bg-gray-800 xl:rounded-tr-xl",
      heading: "UI/UX Design",
      discretion:
        "We understand the importance of a compelling user interface and smooth user experience. Our UI/UX designers work closely with our development teams to create interfaces that not only look great but also enhance usability.",
    },
    {
      divStyle: "bg-gray-800",
      heading: "Cloud Services",
      discretion:
        "Leverage the power of the cloud with PhynLabz. We provide cloud-based solutions that enable scalability, accessibility, and cost-efficiency, ensuring your software performs at its best.",
    },
    {
      divStyle: "bg-[#2a303e]",
      heading: "E-commerce Solutions",
      discretion:
        "Drive online success with our e-commerce solutions. We build robust and user-friendly e-commerce platforms that help you reach a wider customer base and increase sales.",
    },
    {
      divStyle: "bg-gray-800",
      heading: "Quality Assurance",
      discretion:
        "Quality is paramount at PhynLabz. Our dedicated QA team conducts rigorous testing to ensure that every software product we deliver is free of defects and performs flawlessly.",
    },
    {
      divStyle: "bg-[#2a303e]",
      heading: "Agile Development",
      discretion:
        "Our agile development methodology ensures flexibility and adaptability throughout the project lifecycle. We collaborate closely with clients, allowing for iterative development and rapid response to changing requirements.",
    },
  ];

  const OurServicescardData2 = [
    {
      divStyle: "bg-[#2a303e] xl:rounded-bl-xl",
      heading: "Content Management Systems (CMS)",
      discretion:
        "PhynLabz provides custom CMS solutions that empower you to easily manage and update your website or app's content, ensuring it stays fresh and engaging for your audience.",
    },
    {
      divStyle: "bg-gray-800 xl:rounded-br-xl",
      heading: "Maintenance & Support",
      discretion:
        "We offer ongoing maintenance and support services to keep your software running smoothly. PhynLabz ensures your applications stay up-to-date, secure, and responsive to evolving needs.",
    },
  ];

  return (
    <div>
      <div className="bg-gray-900 flex justify-center Our-box-img">
        <div className="w-full bg-[rgba(15,23,42,.7)] flex justify-center">
          <div className=" text-white flex flex-col  xl:w-[80%] mx-5 items-center justify-center my-20 tablet:w-[95%] tablet:flex-col">
            <div className="text-center text-xl font-bold my-10 xl:text-3xl xl:mb-20 pb-5 border-b">
              <p className="">Our Services</p>
            </div>

            <div className="w-full flex flex-col xl:flex-row flex-wrap tablet:flex-row">
              {OurServicescardData1.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`xl:w-[25%] xl:h-[22rem] h-[17rem] tablet:h-[24rem] tablet:w-[50%] px-5  py-3  flex items-center justify-start flex-col text-center border border-gray-300  relative ${item.divStyle} `}
                  >
                    <p className="font-bold text-lg xl:text-2xl tablet:text-2xl text-white my-4">
                      {item.heading}
                    </p>
                    <p className="text-xs xl:text-sm xl:my-2 text-gray-400 tablet:text-base tablet:mt-2">
                      {item.discretion}
                    </p>
                    <p className="my-5 mt-5 absolute bottom-5">
                      <Link
                        to=""
                        className="border border-orange-600 hover:bg-orange-600 text-white px-6 py-2 rounded-md"
                      >
                        Know More
                      </Link>
                    </p>
                  </div>
                );
              })}

              {OurServicescardData2.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`xl:w-[50%] xl:h-[15rem] h-[17rem] tablet:h-[24rem] tablet:w-[50%] px-5  py-3  flex items-center justify-start flex-col text-center border border-gray-300 relative ${item.divStyle}  `}
                  >
                    <p className="font-bold text-lg xl:text-2xl tablet:text-2xl text-white my-4">
                      {item.heading}
                    </p>
                    <p className="text-xs xl:text-sm xl:my-2 text-gray-400 tablet:text-base tablet:mt-2">
                      {item.discretion}
                    </p>
                    <p className="my-5 mt-5 absolute bottom-5">
                      <Link
                        to=""
                        className="border border-orange-600 hover:bg-orange-600 text-white px-6 py-2 rounded-md"
                      >
                        Know More
                      </Link>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
