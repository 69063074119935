import React from "react";

import "./DevelopmentContainer.css";

function DevelopmentContainer() {
  return (
    <div>
      <div className=" flex justify-center Development-box-img">
        <div className="w-full bg-[rgba(15,23,42,.7)] flex justify-center">
          <div className=" text-white flex flex-col xl:flex-row  xl:w-[80%] mx-5 items-center justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 my-20">
            <div className="xl:w-[50%] tablet:w-[50%] my-10 mx-10">
              <p className="font-bold text-2xl xl:text-4xl my-5">
                Transforming ideas into reality through relentless dedication.
              </p>
              <p className="text-xs xl:text-base">
                We take your concepts, dreams, and goals seriously. Our team is
                committed to working tirelessly, with unwavering focus and
                determination, to bring your vision to life in the form of
                practical, tangible solutions. We leave no stone unturned to
                ensure that every project we undertake is executed with the
                utmost care and precision, reflecting our unwavering commitment
                to delivering excellence in every endeavor.
              </p>
            </div>

            <div className="xl:w-[50%] tablet:w-[50%]  bg-[rgba(15,23,42,.9)] rounded-3xl p-5 flex items-center justify-center">
              <img src={require("../../../assets/HomeImg/a.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentContainer;
