import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./RefundPolicy.css";

function RefundPolicy() {
  const Access =
    "Deliver a high quality web design. *Support our customers once signing a contract. ";

  const AccessArr = Access.split("*");

  const Shipping =
    "Streamline an expanded array of web. *Pursue web-enabled niche markets with professionalsDeliver a high quality web design. ";

  const ShippingArr = Shipping.split("*");

  return (
    <div>
      <Header />

      {true && (
        <div className="rp-bg-img">
          <div className=" bg-[rgba(15,23,42,.7)] text-white flex justify-center flex-col items-center">
            <div className="p-10 bg-gradient-to-r text-xl md:text-3xl font-bold 2xl:mb-10 bg-[rgba(15,23,42,.9)] w-[90%] 2xl:w-[75%] rounded-2xl text-center my-10 ">
              <p className="">REFUND POLICY</p>
            </div>

            <div className="w-[90%] 2xl:w-[60%] tablet:w-[70%] bg-[rgba(15,23,42,.9)] py-5  my-5 mb-10  px-5  md:px-10 rounded-3xl">
              <ul className="list-decimal font-mono mx-5 w-full ">
                {/* <li className="font-bold text-base md:text-xl my-8">
                  Access to Our Sites
                  {AccessArr.map((data) => (
                    <ul className="font-sans list-disc m-5">
                      <li className="font-normal text-sm md:text-lg my-2 ">
                        {data}
                      </li>
                    </ul>
                  ))}
                </li>
                <li className="font-bold text-base md:text-xl my-4">
                  Shipping and Delivery (Not Applicable)
                  {ShippingArr.map((data) => (
                    <ul className="font-sans list-disc m-5">
                      <li className="font-normal text-sm md:text-lg my-4 ">
                        {data}
                      </li>
                    </ul>
                  ))}
                </li> */}
                Refund Policy Returns Our policy lasts 30 days. If 30 days have
                gone by since your purchase, unfortunately we can’t offer you a
                refund or exchange. To be eligible for a return, your item must
                be unused and in the same condition that you received it. It
                must also be in the original packaging. Several types of goods
                are exempt from being returned. Perishable goods such as food,
                flowers, newspapers or magazines cannot be returned. We also do
                not accept products that are intimate or sanitary goods,
                hazardous materials, or flammable liquids or gases. Additional
                non-returnable items: Gift cards Downloadable software products
                Some health and personal care items To complete your return, we
                require a receipt or proof of purchase. Please do not send your
                purchase back to the manufacturer. There are certain situations
                where only partial refunds are granted: (if applicable) Book
                with obvious signs of use CD, DVD, VHS tape, software, video
                game, cassette tape, or vinyl record that has been opened. Any
                item not in its original condition, is damaged or missing parts
                for reasons not due to our error. Any item that is returned more
                than 30 days after delivery Refunds (if applicable) Once your
                return is received and inspected, we will send you an email to
                notify you that we have received your returned item. We will
                also notify you of the approval or rejection of your refund. If
                you are approved, then your refund will be processed, and a
                credit will automatically be applied to your credit card or
                original method of payment, within a certain amount of days.
                Late or missing refunds (if applicable) If you haven’t received
                a refund yet, first check your bank account again. Then contact
                your credit card company, it may take some time before your
                refund is officially posted. Next contact your bank. There is
                often some processing time before a refund is posted. If you’ve
                done all of this and you still have not received your refund
                yet, please contact us at business@phynlabz.com. Sale items (if
                applicable) Only regular priced items may be refunded,
                unfortunately sale items cannot be refunded. Exchanges (if
                applicable) We only replace items if they are defective or
                damaged. If you need to exchange it for the same item, send us
                an email at business@phynlabz.com and send your item to: A-806,
                Satyam, Shrey Bavdhan, Pune City, Pune - 411021, Maharashtra.
                Gifts If the item was marked as a gift when purchased and
                shipped directly to you, you’ll receive a gift credit for the
                value of your return. Once the returned item is received, a gift
                certificate will be mailed to you. If the item wasn’t marked as
                a gift when purchased, or the gift giver had the order shipped
                to themselves to give to you later, we will send a refund to the
                gift giver and he will find out about your return. Shipping To
                return your product, you should mail your product to: A-806,
                Satyam, Shrey Bavdhan, Pune City, Pune - 411021, Maharashtra.
                You will be responsible for paying for your own shipping costs
                for returning your item. Shipping costs are non-refundable. If
                you receive a refund, the cost of return shipping will be
                deducted from your refund. Depending on where you live, the time
                it may take for your exchanged product to reach you, may vary.
                If you are shipping an item over $75, you should consider using
                a trackable shipping service or purchasing shipping insurance.
                We don’t guarantee that we will receive your returned item. We
                offer 7 days return policy only.
              </ul>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default RefundPolicy;
