import React, { useState } from 'react'


import './ClientHeroContainer.css'

function ClientHeroContainer() {

    const [enterMouse, setEnterMouse] = useState(false)


    const handleEnterMouse = (value) => {
        console.log('Enter call huaa .............', value)



        if (value === 'apollo') {

            console.log('value apollo hai')
            setEnterMouse('apollo')
        }
        if (value === 'cardekho') {

            console.log('value cardekho hai')
            setEnterMouse('cardekho')
        }


    }

    const handleLeaveMouse = (value) => {
        console.log('Leave call huaa .............', value)

        setEnterMouse(false)



    }

    return (
        <div>

            <div className=" flex justify-center flex-col items-center  w-full " >



                <div className="   flex justify-center flex-col items-center client-bg-img ">

                    <div className=" text-white flex flex-col xl:flex-row  xl:w-[80%] mx-5 items-start  justify-center tablet:w-[95%] tablet:flex-row tablet:px-5 py-10  bg-[rgba(0,0,0,.4)] px-5 md:px-10 my-20 rounded-3xl">

                        <div className="xl:w-[50%] tablet:w-[50%]     p-10 flex justify-center flex-col items-center">

                            {
                                enterMouse != 'apollo' && enterMouse != 'cardekho' &&
                                <div className='flex justify-center flex-col items-center'>
                                    <p className="font-bold text-2xl xl:text-4xl  text-center ">Clients</p>
                                    <div className="h-[2px] bg-white w-[30%] my-5 "></div>
                                    <p className="text-xs xl:text-base ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque maxime eius dicta culpa fugit accusamus architecto sit, reiciendis perferendis,  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente iure omnis dignissimos, tenetur natus repudiandae quidem nulla dolorem, blanditiis, sequi ipsa iusto. Repellat dolore, quidem qui soluta earum ea maiores?   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae soluta cum cupiditate iste tempore odit laborum. Eveniet non, autem repellendus numquam modi, voluptatem similique cum hic reiciendis et, maxime dignissimos.</p>
                                </div>
                            }

                            {
                                enterMouse === 'apollo' && <div className="flex justify-center flex-col items-center">
                                    <p className="font-bold text-2xl xl:text-4xl  text-center ">Apollo</p>
                                    <div className="h-[2px] bg-white w-[30%] my-5 "></div>
                                    <p className="text-xs xl:text-base ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque maxime eius dicta culpa fugit accusamus architecto sit, reiciendis perferendis,  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente iure omnis dignissimos, tenetur natus repudiandae quidem nulla dolorem, blanditiis, sequi ipsa iusto. Repellat dolore, quidem qui soluta earum ea maiores?   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae soluta cum cupiditate iste tempore odit laborum. Eveniet non, autem repellendus numquam modi, voluptatem similique cum hic reiciendis et, maxime dignissimos.</p>
                                </div>
                            }
                            {
                                enterMouse === 'cardekho' && <div className="flex justify-center flex-col items-center">
                                    <p className="font-bold text-2xl xl:text-4xl text-center ">Cardekho</p>
                                    <div className="h-[2px] bg-white w-[30%] my-5 "></div>
                                    <p className="text-xs xl:text-base ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque maxime eius dicta culpa fugit accusamus architecto sit, reiciendis perferendis,  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente iure omnis dignissimos, tenetur natus repudiandae quidem nulla dolorem, blanditiis, sequi ipsa iusto. Repellat dolore, quidem qui soluta earum ea maiores?   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae soluta cum cupiditate iste tempore odit laborum. Eveniet non, autem repellendus numquam modi, voluptatem similique cum hic reiciendis et, maxime dignissimos.</p>
                                </div>
                            }

                        </div>

                        <div className="w-full xl:w-[50%] tablet:w-[50%] mt-10 md:mt-0  px-10 flex  item-center justify-center flex-wrap">

                            <div className=" w-[15rem] p-2 mx-5" onMouseEnter={() => handleEnterMouse('apollo')} onMouseLeave={handleLeaveMouse} >
                                <img src={require('../../../assets/Client-Image/Apollo.png')} alt="" className='w-full' />
                            </div>
                            <div className=" w-[15rem] p-2 mx-5" onMouseEnter={() => handleEnterMouse('cardekho')} onMouseLeave={handleLeaveMouse} >
                                <img src={require('../../../assets/Client-Image/cardekho.jpeg')} alt="" className='w-full' />
                            </div>



                        </div>



                    </div>

                </div>

            </div>

        </div>
    )
}

export default ClientHeroContainer