import React, { useState } from "react";

import { FaAws } from "react-icons/fa6";
import { MdOutlineWifiTethering, MdShoppingCart } from "react-icons/md";
import { BsQrCodeScan } from "react-icons/bs";
import { BiAtom } from "react-icons/bi";
import { AiOutlineAntDesign } from "react-icons/ai";
import { AiFillBuild } from "react-icons/ai";
import { CiDeliveryTruck } from "react-icons/ci";


import "./BoxesContainer.css";

function BoxesContainer() {
  const [box1Hover, setBox1Hover] = useState(false);
  const [box2Hover, setBox2Hover] = useState(false);
  const [box3Hover, setBox3Hover] = useState(false);
  const [box4Hover, setBox4Hover] = useState(false);

  return (
    <div>
      <div className="bg-gray-900  Boxes-bg-img">
        <div className="w-full bg-[rgba(15,23,42,.7)] flex justify-center">
          <div className=" text-white flex flex-col  xl:w-[80%] mx-5 items-center justify-center my-10 tablet:w-[95%] tablet:flex-col">
            <div className="w-full flex flex-col xl:flex-row flex-wrap tablet:flex-row ">
              <div
                className={`xl:w-[24%] tablet:w-[47%]    py-0  my-3 mx-auto  flex items-center justify-center flex-col text-start border cursor-pointer  border-gray-400 rounded-3xl transition-all duration-200 ${
                  box1Hover ? "Box1" : ""
                } `}
                onMouseEnter={() => setBox1Hover(true)}
                onMouseLeave={() => setBox1Hover(false)}
              >
                <div
                  className={`px-5 pb-7 py-3 rounded-3xl  transition-all duration-200 ${
                    box1Hover ? "bg-[rgba(15,23,42,.5)]" : ""
                  } `}
                >
                  <p className=" w-full">
                    <BiAtom
                      className={`flex items-start     text-6xl justify-start p-2 rounded-full  my-5 transition-all duration-200 ${
                        box1Hover ? "bg-white text-green-600" : "bg-green-600"
                      }`}
                    />
                  </p>
                  <p className="font-bold text-lg xl:text-2xl tablet:text-3xl text-white mb-2  w-full">
                    Research
                  </p>
                  <p className="text-xs xl:text-sm xl:my-2 text-white tablet:text-base tablet:mt-2">
                    Research is the bedrock of PhynLabz's software development.
                    It involves in-depth market analysis, competitor evaluation,
                    and exploration of emerging tech. This comprehensive
                    understanding informs our solutions, ensuring they're
                    tailored precisely to clients' needs and industry trends.
                  </p>
                </div>

                {/* <img src={require('../../../assets/HomeImg/IrF.gif')} alt="" className='' /> */}
              </div>

              <div
                className={`xl:w-[24%] tablet:w-[47%]  my-3 mx-auto  flex items-center justify-center flex-col text-start border cursor-pointer  border-gray-400 rounded-3xl transition-all duration-200 ${
                  box2Hover ? "Box1" : ""
                } `}
                onMouseEnter={() => setBox2Hover(true)}
                onMouseLeave={() => setBox2Hover(false)}
              >
                <div
                  className={` px-5 pb-7 py-3 rounded-3xl transition-all duration-200 ${
                    box2Hover ? "bg-[rgba(15,23,42,.5)]" : ""
                  } `}
                >
                  <p className=" w-full">
                    <AiOutlineAntDesign
                      className={`flex items-start    text-6xl justify-start p-2 rounded-full  my-5 transition-all duration-200 ${
                        box2Hover ? "bg-white text-green-600" : "bg-green-600"
                      }`}
                    />
                  </p>
                  <p className="font-bold text-lg xl:text-2xl tablet:text-3xl text-white mb-2  w-full">
                    Designing
                  </p>
                  <p className="text-xs xl:text-sm xl:my-2 text-white tablet:text-base tablet:mt-2">
                    PhynLabz focuses on both aesthetics and functionality during
                    the design phase. We create user-friendly interfaces and
                    experiences while also designing robust technical
                    architectures. Collaboration between designers and
                    developers ensures visually appealing, seamlessly
                    functioning solutions that meet clients' objectives.
                  </p>
                </div>
              </div>

              <div
                className={`xl:w-[24%] tablet:w-[47%]  my-3 mx-auto  flex items-center justify-center flex-col text-start border cursor-pointer  border-gray-400 rounded-3xl transition-all duration-200 ${
                  box3Hover ? "Box1" : ""
                } `}
                onMouseEnter={() => setBox3Hover(true)}
                onMouseLeave={() => setBox3Hover(false)}
              >
                <div
                  className={` px-5 pb-7 py-3 rounded-3xl transition-all duration-200 ${
                    box3Hover ? "bg-[rgba(15,23,42,.5)]" : ""
                  } `}
                >
                  <p className=" w-full">
                    <AiFillBuild
                      className={`flex items-start    text-6xl justify-start p-2 rounded-full  my-5 transition-all duration-200 ${
                        box3Hover ? "bg-white text-green-600" : "bg-green-600"
                      }`}
                    />
                  </p>
                  <p className="font-bold text-lg xl:text-2xl tablet:text-3xl text-white mb-2  w-full">
                    Building
                  </p>
                  <p className="text-xs xl:text-sm xl:my-2 text-white tablet:text-base tablet:mt-2">
                    In the building phase, our developers use cutting-edge
                    technologies to transform designs into functional code.
                    Rigorous testing and iterative refinement ensure
                    high-quality, secure, and performant software that aligns
                    with clients' goals.
                  </p>
                </div>
              </div>

              <div
                className={`xl:w-[24%] tablet:w-[47%]  my-3 mx-auto  flex items-center justify-center flex-col text-start border cursor-pointer  border-gray-400 rounded-3xl transition-all duration-200 ${
                  box4Hover ? "Box1" : ""
                } `}
                onMouseEnter={() => setBox4Hover(true)}
                onMouseLeave={() => setBox4Hover(false)}
              >
                <div
                  className={` px-5 pb-7 py-3 rounded-3xl transition-all duration-200 ${
                    box4Hover ? "bg-[rgba(15,23,42,.5)]" : ""
                  } `}
                >
                  <p className=" w-full">
                    <CiDeliveryTruck
                      className={`flex items-start    text-6xl justify-start p-2 rounded-full  my-5 transition-all duration-200 ${
                        box4Hover ? "bg-white text-green-600" : "bg-green-600 "
                      }`}
                    />
                  </p>
                  <p className="font-bold text-lg xl:text-2xl tablet:text-3xl text-white mb-2  w-full">
                    Deliver
                  </p>
                  <p className="text-xs xl:text-sm xl:my-2 text-white tablet:text-base tablet:mt-2">
                    Delivery marks the transition to clients, encompassing
                    rigorous testing, potential user training, and comprehensive
                    documentation. Our commitment extends post-delivery with
                    ongoing maintenance and support to adapt the software to
                    evolving needs and ensure lasting value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxesContainer;
