import React from 'react'


import ClientHeroContainer from './ClientHeroContainer/ClientHeroContainer'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Clients() {
  return (


    <div>

      <div className="">
        <Header />
      </div>

      <div className="">
        <ClientHeroContainer />
      </div>
      
      <div className="">
        <Footer />
      </div>

    </div>


  )
}

export default Clients