import React from 'react'



import CareerHeroContainer from './CareerHeroContainer/CareerHeroContainer'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Career() {
   return (
      <div>

         <div>
            <Header />
         </div>

         <div>
            <CareerHeroContainer />
         </div>

         <div>
            <Footer />
         </div>

      </div>
   )
}

export default Career
