import React from 'react'


import Header from '../Header/Header'
import ContactHeroContainer from './ContactHeroContainer/ContactHeroContainer'
import Footer from '../Footer/Footer'

function ContactUs() {
  return (
    <div>

      <div>
        <Header />
      </div>

      <div>
        <ContactHeroContainer />
      </div>
      
      <div>
        <Footer />
      </div>

    </div>
  )
}

export default ContactUs
