import React from "react";

import "./AboutUs.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function AboutUs() {
  const aboutString =
    "Deliver a high quality web design *Support our customers once signing a contract *Streamline an expanded array of web *Pursue web-enabled niche markets with professionals";

  const aboutAar = aboutString.split("*");
  console.log(aboutAar);

  return (
    <div>
      <Header />

      <div className="about-bg">
        <div className=" bg-[rgba(15,23,42,.7)] text-white flex justify-center flex-col items-center">
          <div className="p-10 bg-gradient-to-r  text-3xl font-bold 2xl:mb-10 bg-[rgba(15,23,42,.9)] w-[80%] 2xl:w-[75%] rounded-2xl text-center my-10 ">
            <p className="">ABOUT US</p>
          </div>

          <div className="w-[90%] 2xl:w-[60%] tablet:w-[70%]  my-16">
            <div className="flex justify-between flex-col 2xl:flex-row ">
              <div className="w-full 2xl:w-[49%] border border-black">
                <img
                  src={require("../../assets/HomeImg/about.png")}
                  alt=""
                  className="h-full "
                />
              </div>
              <div className="w-full 2xl:w-[49%] my-5">
                <p className="text-3xl font-bold font-mono">WHO WE ARE ?</p>
                <div className="h-[2px] bg-white w-[4rem] mt-1"></div>
                <p className="my-5">
                  Phynlabz Tech Pvt. Ltd. is founded by some young engineers who
                  have mastered the IT sector, whose objective is to achieve the
                  highest position in the IT sector across the country.who are
                  trying to achieve this objective by cooperating in various
                  fields.
                </p>

                <ul className=" ps-5 mt-5 list-disc list-outside">
                  {aboutAar.map((data) => (
                    <li className="">{data}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="w-full my-10">
              <p className="text-xl font-bold font-mono">OUR STRATEGY</p>
              <div className="h-[2px] bg-white w-[4rem] mt-1 "></div>
              <p className="my-5 text-base">
                Methodology frames the center of an association{" "}
                <b>PHYNLABZ TECH PVT. LTD </b> , one of the counseling firms in
                system help associations to create vital bits of knowledge that
                encourage organization development and long haul objectives. In
                the present questionable business condition, associations need
                to guarantee that they make an adaptable yet powerful
                arrangement settling on educated decisions, impart obviously
                what the arrangement implies for each degree of business
                divisions/works and give consistent execution and control
                components that enable all to accomplish the arrangement.
              </p>
            </div>

            <div className="flex justify-between flex-col 2xl:flex-row ">
              <div className="w-full 2xl:w-[49%] my-5">
                <p className="text-xl font-bold font-mono">ABOUT OUR COMPANY</p>
                <div className="h-[2px] bg-white w-[4rem] mt-1"></div>
                <p className="my-5">
                  Phynlabz Tech Pvt. Ltd. is founded by some young engineers who
                  have mastered the IT sector, whose objective is to achieve the
                  highest position in the IT sector across the country.who are
                  trying to achieve this objective by cooperating in various
                  fields.
                </p>

                <ul className=" ps-5 mt-5 list-disc list-outside">
                  {aboutAar.map((data) => (
                    <li className="">{data}</li>
                  ))}
                </ul>
              </div>
              <div className="w-full 2xl:w-[49%] border border-black">
                <img
                  src={require("../../assets/HomeImg/about.png")}
                  alt=""
                  className="h-full "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
