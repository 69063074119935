import React, { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { Link } from "react-router-dom";

import { RiArrowDownSLine, RiArrowDropRightLine } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";
import { BiSolidDownArrow } from "react-icons/bi";
import ServicesDropdown from "./ServicesDropdown/ServicesDropdown";
import IndustriesDropdown from "./IndustriesDropdown/IndustriesDropdown";
import CompanyDropdown from "./CompanyDropdown/CompanyDropdown";

function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  const [arrowRotate, setArrowRotate] = useState(false);
  const [showDropdown, setShowDropdown] = useState("");

  const handleMouseEnter = (value) => {
    if (showDropdown === value) {
      setShowDropdown("");
      setArrowRotate("");
      return;
    }

    if (value === "industries") {
      setArrowRotate("industries");
      setShowDropdown("industries");
    }
    if (value === "company") {
      setArrowRotate("company");
      setShowDropdown("company");
    }
    if (value === "services") {
      setArrowRotate("services");
      setShowDropdown("services");
    }
  };

  return (
    <div className="z-50 ">
      <div className="bg-gray-900 flex justify-center w-full text-white 2xl:h-[5rem] ">
        <div className="w-full mx-2 md:mx-0 xl:w-[95%] tablet:w-[90%]  flex py-2 ">
          <div className=" mx-1  w-[70%] flex items-center">
            <Link to="/home">
              <div className="-translate-y-2">
                <img
                  src={require("../../assets/HomeImg/logo-phynlabz.png")}
                  alt=""
                  className="h-[3rem] xl:h-[3.5rem]"
                />
              </div>
            </Link>

            {/* <p className="text-xl xl:text-4xl px-1 font-bold ">Phynlabz <span className="" >Tech</span></p> */}
          </div>

          <div className=" mx-1 px-5 xl:w-full flex items-center justify-end w-[30%]  ">
            <ul className=" md:flex  md:flex-row hidden md:block tablet:hidden md:items-center ">
              <li className=" mx-2 hover:-translate-y-2 duration-500 ">
                <Link
                  to="/home"
                  className="border-b-2 border-transparent py-2   pb-3 hover:border-red-500 px-1 "
                >
                  Home
                </Link>
              </li>
              {/* <li className=" mx-2 hover:-translate-y-2 duration-500 ">
                <Link
                  to="/home"
                  className="border-b-2 border-transparent py-2   pb-3 hover:border-red-500 px-1 "
                >
                  Product
                </Link>
              </li> */}
              {/* <li
                className=" mx-2  "
                onMouseEnter={() => {
                  handleMouseEnter("services");
                }}
                onMouseLeave={() => {
                  setArrowRotate(false);
                  setShowDropdown(false);
                }}
              >
                <Link
                  to="/service"
                  className="border-b-2 border-transparent  py-3  pb-3  px-1  flex items-center"
                >
                  Services
                  <BiSolidDownArrow
                    className={`mx-2  duration-500 ${
                      arrowRotate === "services" ? "rotate-180" : ""
                    }`}
                  />
                </Link>

                {showDropdown === "services" && (
                  <ServicesDropdown className="absolute" />
                )}
              </li> */}
              {/* <li className=" mx-2 hover:-translate-y-2 duration-500 ">
                <Link
                  to="/home"
                  className="border-b-2 border-transparent py-2   pb-3 hover:border-red-500 px-1 "
                >
                  Technology
                </Link>
              </li> */}
              {/* <li
                className=" mx-2  "
                onMouseEnter={() => {
                  handleMouseEnter("industries");
                }}
                onMouseLeave={() => {
                  setArrowRotate(false);
                  setShowDropdown(false);
                }}
              >
                <Link
                  to="/home"
                  className="border-b-2 border-transparent py-3  pb-3   px-1 flex items-center"
                >
                  Industries
                  <BiSolidDownArrow
                    className={`mx-2  duration-500 ${
                      arrowRotate === "industries" ? "rotate-180" : ""
                    }`}
                  />
                </Link>

                {showDropdown === "industries" && <IndustriesDropdown />}
              </li> */}
              {/* <li className=" mx-2 hover:-translate-y-2 duration-500 ">
                <Link
                  to="/career"
                  className="border-b-2 border-transparent py-2   pb-3 hover:border-red-500 px-1 "
                >
                  Career
                </Link>
              </li> */}
              {/* <li className=" mx-2 hover:-translate-y-2 duration-500 ">
                <Link
                  to="/clients"
                  className="border-b-2 border-transparent py-2   pb-3 hover:border-red-500 px-1 "
                >
                  Clients
                </Link>
              </li> */}

              {/* <li
                className=" mx-2  duration-500 "
                onMouseEnter={() => {
                  handleMouseEnter("company");
                }}
                onMouseLeave={() => {
                  setArrowRotate(false);
                  setShowDropdown(false);
                }}
              >
                <Link
                  to="/home"
                  className="border-b-2 border-transparent py-3   pb-3  px-1 flex items-center "
                >
                  Company
                  <BiSolidDownArrow
                    className={`mx-2  duration-500 ${
                      arrowRotate === "company" ? "rotate-180" : ""
                    }`}
                  />
                </Link>

                {showDropdown === "company" && <CompanyDropdown />}
              </li> */}

              <li className=" mx-2  hover:-translate-y-2 duration-500">
                <Link
                  to="/contact"
                  className="border-b-2 border-transparent py-2  pb-2 hover:border-red-500 px-1 "
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            <div className="md:hidden  tablet:block">
              <Link
                className="text-3xl"
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              >
                <AiOutlineMenu className="" />
              </Link>
            </div>
          </div>
        </div>

        {/* mobile ke liye start*/}

        {/* main menu code open close start */}

        <AnimatePresence>
          {openMenu && (
            <motion.div
              className="w-full absolute right-0 left-0 mx-auto top-16  "
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                duration: 0.2,
              }}
            >
              <div
                className={`md:hidden tablet:block tablet:w-[90%] bg-white flex justify-center z-50 items-center  w-full   pt-5  absolute right-0 left-0 mx-auto border-t-4 rounded-lg border-orange-500`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <ul className={" flex flex-col justify-center w-full px-3"}>
                  <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link to="/home" className="flex items-center text-black ">
                      Home{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li>
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/contact"
                      className="flex items-center text-black "
                    >
                      Product{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/home"
                      className="flex items-center text-black "
                      onClick={() => {
                        handleMouseEnter("services");
                      }}
                    >
                      Services
                      <RiArrowDownSLine
                        className={`ms-auto text-2xl text-gray-600 transition-all duration-300 ${
                          arrowRotate === "services" ? "rotate-180" : ""
                        } `}
                      />
                    </Link>

                    {showDropdown === "services" && <ServicesDropdown />}
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/contact"
                      className="flex items-center text-black "
                    >
                      Technology{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/home"
                      className="flex items-center text-black "
                      onClick={() => {
                        handleMouseEnter("industries");
                      }}
                    >
                      Industries
                      <RiArrowDownSLine
                        className={`ms-auto text-2xl text-gray-600 transition-all duration-300 ${
                          arrowRotate === "industries" ? "rotate-180" : ""
                        } `}
                      />
                    </Link>

                    {showDropdown === "industries" && <IndustriesDropdown />}
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/career"
                      className="flex items-center text-black "
                    >
                      Career{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/contact"
                      className="flex items-center text-black "
                    >
                      Clients{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li> */}
                  {/* <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/home"
                      className="flex items-center text-black "
                      onClick={() => {
                        handleMouseEnter("company");
                      }}
                    >
                      Company
                      <RiArrowDownSLine
                        className={`ms-auto text-2xl text-gray-600 transition-all duration-300 ${
                          arrowRotate === "company" ? "rotate-180" : ""
                        } `}
                      />
                    </Link>

                    {showDropdown === "company" && <CompanyDropdown />}
                  </li> */}
                  <li className="   text-lg ps-2  py-2  mx-1 my-1 border-b-2 mb-3">
                    <Link
                      to="/contact"
                      className="flex items-center text-black "
                    >
                      Contact Us{" "}
                      <RiArrowDropRightLine className="ms-auto text-3xl text-gray-600" />
                    </Link>
                  </li>
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* main menu code open close end */}

        {/* mobile ke liye end */}
      </div>
    </div>
  );
}

export default Header;
